import React, { useEffect, useState } from 'react';
import './Feedback.scss';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';


const Feedback = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [countdown, setCountdown] = useState(180);

    useEffect(() => {
        const emailSentTimestamp = localStorage.getItem('emailSentTimestamp');
        if (emailSentTimestamp) {
            const timeElapsed = Math.floor((Date.now() - parseInt(emailSentTimestamp)) / 1000);
            if (timeElapsed < 180) {
                setEmailSent(true);
                setCountdown(180 - timeElapsed);
            }
        }
    }, []);

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;

        if (emailSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0 && timer) {
            clearInterval(timer);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [emailSent, countdown]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const response = await fetch('http://localhost:3000/api/mailer/sendSuggestionMail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    subject: subject,
                    message: message,
                })
            });
            if (response.ok) {
                setEmailSent(true);
                localStorage.setItem('emailSentTimestamp', Date.now().toString());
                console.log('Email sent successfully');
            } else {
                console.error('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const handleBackButtonClick = () => {
        if (countdown === 0) {
            setEmail("");
            setSubject("");
            setMessage("");
            setTermsAccepted(false);
            setEmailSent(false);
            setCountdown(180);
            localStorage.removeItem('emailSentTimestamp');
        }
    };

    return (
        <>
            <section id='ayudanos-a-mejorar' className="feedback-form">
                <div className='feedback-info'>
                    <h2>{t('feedback.title')}</h2>
                    <p>{t('feedback.subtitle')}</p>
                    <p>{t('feedback.description1')}</p>
                    <p>{t('feedback.description2')}</p>
                </div>
                {!emailSent ? (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>{t('feedback.emailLabel')}</label>
                            <input
                                type="email"
                                placeholder={t('feedback.emailPlaceholder')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>{t('feedback.subjectLabel')}</label>
                            <input
                                type="text"
                                placeholder={t('feedback.subjectPlaceholder')}
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>{t('feedback.messageLabel')}</label>
                            <textarea
                                placeholder={t('feedback.messagePlaceholder')}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="checkbox-group">
                            <input
                                type="checkbox"
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                required
                            />
                            <label onClick={() => navigate('/terms-conditions')} className='labeltcs'>{t('feedback.termsLabel')}</label>
                        </div>
                        <button type="submit">{t('feedback.submitButton')}</button>
                    </form>
                ) : (
                    <div className="email-sent">
                        <div className="message-container">
                            <p>{t('feedback.emailSuccess')}</p>
                            <p>{t('feedback.waitMessage')}</p>
                        </div>
                        <button
                            onClick={handleBackButtonClick}
                            className={`back-button ${countdown > 0 ? 'disabled' : ''}`}
                            disabled={countdown > 0}
                        >
                            {t('feedback.goBack')} {countdown > 0 && `(${countdown}s)`}
                        </button>
                    </div>
                )}
            </section>
            <Footer />
        </>
    )
}

export default Feedback